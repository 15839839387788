import React from "react"
import { graphql } from "gatsby"

import Header from "@components/header"
import Background from "@images/svg/NotFoundGraphic.svg"
import FadeLink from "@components/fade-link"

const NotFoundTemplate = () => {
  return (
    <div style={{ position: `relative` }}>
      <Header />
      <main className={`inner-page-wrapper`}>
        <div
          className="container has-text-centered"
          style={{ marginTop: `20rem` }}
        >
          <div className="columns">
            <div className="column is-6 is-offset-3">
              <h1>
                <span className="h1">{`404`}</span>
                <span className="h3">{`Page Not Found`}</span>
              </h1>

              <p>{`Oops! This page doesn't exist.`}</p>

              <FadeLink
                to={`/`}
                className="button"
                style={{ marginTop: `4rem` }}
              >
                {`Go Back Home`}
              </FadeLink>
            </div>
          </div>
        </div>
      </main>
      <div
        className="background"
        style={{
          position: `fixed`,
          bottom: `-2rem`,
          left: 0,
          width: `100%`,
          zIndex: `-1`,
          pointerEvents: `none`,
        }}
      >
        <Background style={{ width: `100%` }} />
      </div>
    </div>
  )
}

export default NotFoundTemplate
